var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    class: "fill-current  text-".concat(_vm.color),
    attrs: {
      "height": "".concat(_vm.height, "px"),
      "width": "".concat(_vm.width, "px"),
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M7 0.5C3.41594 0.5 0.5 3.41594 0.5 7C0.5 10.5841 3.41594 13.5 7 13.5C10.5841 13.5 13.5 10.5841 13.5 7C13.5 3.41594 10.5841 0.5 7 0.5ZM10.3828 4.82156L6.18281 9.82156C6.13674 9.87644 6.07941 9.92077 6.01471 9.95156C5.95001 9.98234 5.87945 9.99886 5.80781 10H5.79938C5.72929 9.99997 5.66 9.98522 5.59599 9.95668C5.53198 9.92815 5.47468 9.88648 5.42781 9.83438L3.62781 7.83438C3.5821 7.78589 3.54654 7.72876 3.52322 7.66633C3.4999 7.60391 3.4893 7.53745 3.49203 7.47087C3.49477 7.40429 3.51078 7.33892 3.53914 7.27862C3.56749 7.21831 3.60761 7.16429 3.65715 7.11971C3.70668 7.07514 3.76463 7.04091 3.82757 7.01905C3.89052 6.99719 3.95721 6.98813 4.02371 6.9924C4.09021 6.99668 4.15518 7.01421 4.21481 7.04396C4.27444 7.0737 4.32752 7.11507 4.37094 7.16562L5.78625 8.73812L9.61719 4.17844C9.70312 4.07909 9.8247 4.01754 9.95564 4.00711C10.0866 3.99668 10.2164 4.03819 10.317 4.12268C10.4175 4.20717 10.4808 4.32784 10.4931 4.45862C10.5055 4.5894 10.4658 4.71977 10.3828 4.82156Z"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }